import React from "react";
import { FaBehanceSquare } from "react-icons/fa";
import { SiLeetcode } from "react-icons/si";

const Footer = () => {
  const year = new Date().getFullYear();
  return (
    <div className="py-20 footer_container  text-gray-600 border-t  ">
      <div className="container">
        <div className="social  flex  mx-auto max-w-[100px] justify-around ">
          <a
            href="https://www.linkedin.com/in/md-tanvir-alam-a328341b3/"
            target="_blank"
            className="mb-2 hover:text-[#0A66C2]"
          >
            <i class="bx bxl-linkedin-square "></i>
          </a>
          <a
            href="https://github.com/Tanvir-Alam625"
            className="mb-2 hover:text-[#24292F]"
            target="_blank"
          >
            <i class="bx bxl-github"></i>
          </a>
          <a
            href="https://www.facebook.com/tanvir.alam625"
            className="mb-2 hover:text-[#0D8CF0]"
            target="_blank"
          >
            <i class="bx bxl-facebook-square"></i>
          </a>
          <a
            href="https://www.behance.net/xv3"
            className="mb-2 hover:text-[#0D8CF0]"
            target="_blank"
          >
            <FaBehanceSquare />
          </a>
          <a
            href="https://leetcode.com/mdtanviralamht731/"
            className="mb-2 hover:text-[#FF8624]"
            target="_blank"
          >
            <SiLeetcode />
          </a>
        </div>
        <p className="text-center text-sm ">
          Copyright ©{year} All rights reserved
        </p>
      </div>
    </div>
  );
};

export default Footer;
