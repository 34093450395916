import React from "react";
// import { useNavigate } from "react-router-dom";
import "./Projects.css"

const Project = ({ project }) => {
  const {  name, liveSite, description,  thumb,clientCode,} = project;
  // const navigate = useNavigate();
  return (
    <div className="relative border project_container  my_project rounded project-container   duration-300  ease-in-out ">
      <div className="img_container">
      <img src={thumb} className="w-full project_img" alt="project"  />
      </div>
      <div className=" flex items-start flex-col py-6 p-2">
        <div className="flex justify-between items-center py-2">
          <a href={liveSite} title="Open Live Site" className="link_container" target="_blank">
            <span className="link_name">Live Site</span>
            <i class='bx bx-link-external'></i>
          </a>
        
           <a href={clientCode} title="Open Live Site"  className="link_container" target="_blank">
           <span className="link_name">Client Code</span>
           <i class='bx bx-link-external'></i>
          </a>
           {
            project?.serverCode &&
            <a href={liveSite} title="Open Live Site"  className="link_container" target="_blank">
           <span className="link_name">Server Code</span>
           <i class='bx bx-link-external'></i>
          </a>
           }

        </div>
        <div className="flex items-center justify-between mb-2">
        <h2 className="text-xl">{name}</h2>
        {
          project?.contribute &&
          <p className="text-xs ml-2">Contribute</p>
        }
        </div>
        <small title={description} className="text-xs text-gray-600">
          {description.length > 100
            ? description.slice(0, 100) + "..."
            : description}
        </small>{" "}
        {/* <button
          onClick={() => navigate(`/details/${id}`)}
          className="py-2 px-4 mt-2  border border-primary bg-transparent   text-xl  rounded duration-300 ease-in-out hover:bg-primary  "
        >
          Project Details
        </button> */}
      </div>
    </div>
  );
};

export default Project;
