import React, { useEffect, useState } from "react";
import Spinner from "../Header/Spinner/Spinner";

import Project from "./Project";
import { productFetcher } from "../../hooks/useProducts";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import { useRef } from "react";

const Projects = () => {
  const [projects, setProjects] = useState([]);
  const [spinner, setSpinner] = useState(true);
  const tabRef = useRef(null);

  useEffect(() => {
    setSpinner(true);
    const getProjects = async () => {
      const projects = await productFetcher();
      setProjects(projects);
    };
    getProjects();
    setSpinner(false);
    return () => {};
  }, []);
  const handleTab = () => tabRef.current && tabRef.current.focus();
  if (spinner) {
    return <Spinner />;
  }
  return (
    <div id="project" className="container py-20 ">
      <div className="title my-4 lg:mt-12 lg:mb-6 ">
        <h2 className="lg:text-4xl md:text-2xl text-xl font-bold    title-project">
          Projects
        </h2>
      </div>

      <Tabs>
        <TabList className="custom-tab-list">
          <Tab ref={tabRef} onClick={handleTab}>
            Full Stack
          </Tab>
          <Tab ref={tabRef} onClick={handleTab}>
            Frontend
          </Tab>
          <Tab ref={tabRef} onClick={handleTab}>
            Mini Tools
          </Tab>
          <Tab ref={tabRef} onClick={handleTab}>
            Backend
          </Tab>
        </TabList>

        <TabPanel>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 my-10">
            {projects
              .filter((project) => project.projectType === "Full-Stack")
              .map((project, index) => (
                <Project key={index} project={project} />
              ))}
          </div>
        </TabPanel>
        <TabPanel>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 my-10">
            {projects
              .filter((project) => project.projectType === "Frontend")
              .map((project, index) => (
                <Project key={index} project={project} />
              ))}
          </div>
        </TabPanel>
        <TabPanel>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 my-10">
            {projects
              .filter((project) => project.projectType === "Tool")
              .map((project, index) => (
                <Project key={index} project={project} />
              ))}
          </div>
        </TabPanel>
        <TabPanel>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 my-10">
            {projects
              .filter((project) => project.projectType === "Backend")
              .map((project, index) => (
                <Project key={index} project={project} />
              ))}
          </div>
        </TabPanel>
      </Tabs>
    </div>
  );
};

export default Projects;
