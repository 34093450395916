import React from "react";

const FrontEnd = () => {
  return (
    <div className="skills_content">
      <h3 className="skills_title">Front End</h3>
      <div className="skills_box">
        <div className="skills_group">
          <div className="Skills_data">
            <i class="bx bxs-badge-check"></i>
          </div>
          <div className="">
            <h3 className="skills_name">HTML</h3>
            <span className="skills_level">Advanced</span>
          </div>
        </div>
        <div className="skills_group">
          <div className="Skills_data">
            <i class="bx bxs-badge-check"></i>
          </div>
          <div className="">
            <h3 className="skills_name">CSS</h3>
            <span className="skills_level">Advanced</span>
          </div>
        </div>
        <div className="skills_group">
          <div className="Skills_data">
            <i class="bx bxs-badge-check"></i>
          </div>
          <div className="">
            <h3 className="skills_name">Bootstrap</h3>
            <span className="skills_level">Intermediate</span>
          </div>
        </div>
        <div className="skills_group">
          <div className="Skills_data">
            <i class="bx bxs-badge-check"></i>
          </div>
          <div className="">
            <h3 className="skills_name">TailwindCSS</h3>
            <span className="skills_level">Advanced</span>
          </div>
        </div>
        <div className="skills_group">
          <div className="Skills_data">
            <i class="bx bxs-badge-check"></i>
          </div>
          <div className="">
            <h3 className="skills_name">Material UI</h3>
            <span className="skills_level">Basic</span>
          </div>
        </div>
        <div className="skills_group">
          <div className="Skills_data">
            <i class="bx bxs-badge-check"></i>
          </div>
          <div className="">
            <h3 className="skills_name">JavaScript</h3>
            <span className="skills_level">Advanced</span>
          </div>
        </div>
        <div className="skills_group">
          <div className="Skills_data">
            <i class="bx bxs-badge-check"></i>
          </div>
          <div className="">
            <h3 className="skills_name">Typescript</h3>
            <span className="skills_level">Intermediate</span>
          </div>
        </div>
        <div className="skills_group">
          <div className="Skills_data">
            <i class="bx bxs-badge-check"></i>
          </div>
          <div className="">
            <h3 className="skills_name">ReactJs</h3>
            <span className="skills_level">Advanced</span>
          </div>
        </div>
        <div className="skills_group">
          <div className="Skills_data">
            <i class="bx bxs-badge-check"></i>
          </div>
          <div className="">
            <h3 className="skills_name">NextJs</h3>
            <span className="skills_level">Intermediate</span>
          </div>
        </div>
        <div className="skills_group">
          <div className="Skills_data">
            <i class="bx bxs-badge-check"></i>
          </div>
          <div className="">
            <h3 className="skills_name">Redux Toolkit</h3>
            <span className="skills_level">Intermediate</span>
          </div>
        </div>
        <div className="skills_group">
          <div className="Skills_data">
            <i class="bx bxs-badge-check"></i>
          </div>
          <div className="">
            <h3 className="skills_name">Firebase</h3>
            <span className="skills_level">Intermediate</span>
          </div>
        </div>
        <div className="skills_group">
          <div className="Skills_data">
            <i class="bx bxs-badge-check"></i>
          </div>
          <div className="">
            <h3 className="skills_name">SASS</h3>
            <span className="skills_level">Intermediate</span>
          </div>
        </div>
      </div>
    </div>
  );
};
export default FrontEnd;
