import React, { useState } from "react"
import "./Services.css"
const Services= ()=>{
    const [modalTab,setModalTab]=useState(0)
    const showModalData=id=>{
        setModalTab(id)
    }
    return(
        <section id="services" className="mb-12 container">
            <div className="title my-4 lg:mt-12 lg:mb-12 ">
                <h2 className="lg:text-4xl md:text-2xl text-xl font-bold title-project">
                Services
                </h2>
            </div>
            <div className="services_container   grid">
                <div className="services_content">
                    <div>
                        <i class='bx bx-code-block' ></i>
                        <h3 className='Services_title'>Web <br/> Design</h3>
                    </div>
                    <span 
                    onClick={()=>showModalData(1)}
                    className="services_button">View More
                    <i className="uil uil-arrow-right services_button-icon"></i>
                    </span>
                    <div className="services_modal">
                        <div className="services_modal_content">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="h-6 w-6 absolute top-[10px] right-[10px] text-gray-600">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                        </svg>
                            <h3 className="sertvices-Modal_title">Web Design</h3>
                            <p className="services_modal_description">
                                service with moree than 1 year of experince.
                                 Providing quality work to client and companies.
                            </p>
                            <ul className="service_modal_services grid">
                                <li className="service_modal_service">
                                    <i className='uil uil-check-circle services_modal_icon'></i>
                                    <p className="servies_modal_info">
                                        I develop the user interface.
                                    </p> 

                                </li>
                                <li className="service_modal_service">
                                    <i className='uil uil-check-circle services_modal_icon'></i>
                                    <p className="servies_modal_info">
                                    Web page development.
                                    </p> 

                                </li>
                                <li className="service_modal_service">
                                    <i className='uil uil-check-circle services_modal_icon'></i>
                                    <p className="servies_modal_info">
                                    I create ux element interactions.
                                    </p> 

                                </li>
                                <li className="service_modal_service">
                                    <i className='uil uil-check-circle services_modal_icon'></i>
                                    <p className="servies_modal_info">
                                    I create ux element interactions.
                                    </p> 

                                </li>
                                <li className="service_modal_service">
                                    <i className='uil uil-check-circle services_modal_icon'></i>
                                    <p className="servies_modal_info">
                                    I position your company brand.
                                    </p> 

                                </li>
                                <li className="service_modal_service">
                                    <i className='uil uil-check-circle services_modal_icon'></i>
                                    <p className="servies_modal_info">
                                    Design and mockups of products for companies.
                                    </p> 

                                </li>

                            </ul>
                        </div>

                    </div>
                </div>
                <div className="services_content">
                    <div>
                    <i class='bx bx-code-block' ></i>
                        <h3 className='Services_title'>Front-End <br/> Devlopment</h3>
                    </div>
                    <span 
                    onClick={()=>showModalData(2)}
                    
                    className="services_button">View More
                    <i className="uil uil-arrow-right services_button-icon"></i>
                    </span>
                    <div className="services_modal">
                        <div className="services_modal_content">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="h-6 w-6 absolute top-[10px] right-[10px] text-gray-600">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                        </svg>
                            <h3 className="sertvices-Modal_title">Front-EndDevlopment</h3>
                            <p className="services_modal_description">
                                service with moree than 1 year of experince.
                                 Providing quality work to client and companies.
                            </p>
                            <ul className="service_modal_services grid">
                                <li className="service_modal_service">
                                    <i className='uil uil-check-circle services_modal_icon'></i>
                                    <p className="servies_modal_info">
                                        I develop the user interface.
                                    </p> 

                                </li>
                                <li className="service_modal_service">
                                    <i className='uil uil-check-circle services_modal_icon'></i>
                                    <p className="servies_modal_info">
                                    Web page development.
                                    </p> 

                                </li>
                                <li className="service_modal_service">
                                    <i className='uil uil-check-circle services_modal_icon'></i>
                                    <p className="servies_modal_info">
                                    I create ux element interactions.
                                    </p> 

                                </li>
                                <li className="service_modal_service">
                                    <i className='uil uil-check-circle services_modal_icon'></i>
                                    <p className="servies_modal_info">
                                    I create ux element interactions.
                                    </p> 

                                </li>
                                <li className="service_modal_service">
                                    <i className='uil uil-check-circle services_modal_icon'></i>
                                    <p className="servies_modal_info">
                                    I position your company brand.
                                    </p> 

                                </li>
                                <li className="service_modal_service">
                                    <i className='uil uil-check-circle services_modal_icon'></i>
                                    <p className="servies_modal_info">
                                    Design and mockups of products for companies.
                                    </p> 

                                </li>

                            </ul>
                        </div>

                    </div>
                </div>
                <div className="services_content">
                    <div>
                    <i class='bx bx-code-block' ></i>
                        <h3 className='Services_title'>Back End <br/> Development</h3>
                    </div>
                    <span
                    onClick={()=>showModalData(3)}
                    
                    className="services_button">View More
                    <i className="uil uil-arrow-right services_button-icon"></i>
                    </span>
                    <div className={`services_modal`}>
                        <div className="services_modal_content">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="h-6 w-6 absolute top-[10px] right-[10px] text-gray-600">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                        </svg>
                            <h3 className="sertvices-Modal_title">Back End Development</h3>
                            <p className="services_modal_description">
                                service with moree than 1 year of experince.
                                 Providing quality work to client and companies.
                            </p>
                            <ul className="service_modal_services grid">
                                <li className="service_modal_service">
                                    <i className='uil uil-check-circle services_modal_icon'></i>
                                    <p className="servies_modal_info">
                                        I develop the user interface.
                                    </p> 

                                </li>
                                <li className="service_modal_service">
                                    <i className='uil uil-check-circle services_modal_icon'></i>
                                    <p className="servies_modal_info">
                                    Web page development.
                                    </p> 

                                </li>
                                <li className="service_modal_service">
                                    <i className='uil uil-check-circle services_modal_icon'></i>
                                    <p className="servies_modal_info">
                                    I create ux element interactions.
                                    </p> 

                                </li>
                                <li className="service_modal_service">
                                    <i className='uil uil-check-circle services_modal_icon'></i>
                                    <p className="servies_modal_info">
                                    I create ux element interactions.
                                    </p> 

                                </li>
                                <li className="service_modal_service">
                                    <i className='uil uil-check-circle services_modal_icon'></i>
                                    <p className="servies_modal_info">
                                    I position your company brand.
                                    </p> 

                                </li>
                                <li className="service_modal_service">
                                    <i className='uil uil-check-circle services_modal_icon'></i>
                                    <p className="servies_modal_info">
                                    Design and mockups of products for companies.
                                    </p> 

                                </li>

                            </ul>
                        </div>

                    </div>
                </div>

            </div>
        </section>
    );
}
export default Services;
