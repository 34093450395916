import emailjs from "@emailjs/browser";
import React, { useRef } from "react";
import { toast } from "react-toastify";
import "./Contact.css";
const Contact = () => {
  const form = useRef();
  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_0ru86jr",
        "template_asbbx92",
        form.current,
        "S62QiZ0e98YJ0RK_h"
      )
      .then(
        (result) => {
          toast("Congratulation Your Message Has been Sent");
        },
        (error) => {
          toast.error("Not send Try Again! ");
        }
      );
    e.target.reset();
  };
  return (
    <div id="contact" className="max-w-[1100px] mx-auto px-2 my-8 lg:my-20">
      <div className="grid grid-cols-1 px-4">
        <div className="title my-4 lg:mt-12 lg:mb-12 ">
          <h2 className="lg:text-4xl md:text-2xl text-xl font-bold title-project">
            Get in Touch
          </h2>
        </div>
        <div className="flex flex-col lg:flex-row gap-4 justify-center items-center">
          <div className="my_info w-[100%] lg:w-[50%]">
            <h3 className="contact_talk_title">Talk With Me</h3>
            <a href="mailto:devtanvir01@gmail.com">
              <div className="grid contact_info">
                <i class="bx bx-mail-send contact_icon"></i>
                <h4>Gmail</h4>
                <span className="text-xs">devtanvir01@gmail.com</span>
              </div>
            </a>
            <a
              href="https://api.whatsapp.com/send/?phone=01704338645"
              target="_blank"
            >
              <div className="grid contact_info">
                <i class="bx bxl-whatsapp contact_icon"></i>
                <h4>Whatsapp</h4>
                <span className="text-xs">+8801704338645</span>
              </div>
            </a>
            <a href="https://www.messenger.com/t/100030796552958">
              <div className="grid contact_info ">
                <i class="bx bxl-whatsapp contact_icon"></i>
                <h4>Messenger</h4>
                <span className="text-xs">/tanvir.alam625</span>
              </div>
            </a>
          </div>
          <form
            ref={form}
            onSubmit={sendEmail}
            className="my-12 flex flex-col items-center justify-end md:w-[100%] lg:w-[50%]"
          >
            <h3 className="contact_talk_title">Write Me Your Project</h3>
            <div className="name w-[100%] mb-4 relative">
              <input
                type="text"
                name="name"
                placeholder="Type Where"
                required
                id="name"
                className="border-2 outline-0 text-sm  py-3 px-4 rounded-xl w-[100%] bg-transparent"
              />
              <label htmlFor="name" className="block input_level text-sm ">
                Name
              </label>
            </div>
            <div className="name w-[100%] mb-4 relative">
              <input
                type="email"
                required
                name="email"
                placeholder="Type Where"
                id="email"
                className="border-2 outline-0 text-sm py-3 px-4 rounded-xl w-[100%] bg-transparent"
              />
              <label htmlFor="name" className="block input_level text-sm ">
                Email
              </label>
            </div>
            <div className="name w-[100%] mb-4 relative">
              <textarea
                name="message"
                required
                id="message"
                placeholder="Type Where"
                cols="30"
                className="border-2 outline-0 text-sm  py-3 px-4 rounded-xl w-[100%] bg-transparent"
                rows="10"
              ></textarea>
              <label htmlFor="message" className="block input_level text-sm ">
                Message
              </label>
            </div>
            <button type="submit" className="btn  text-white  rounded-xl px-6">
              Send Message
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Contact;
