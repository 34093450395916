import React from "react";
import "./Spinner.css";
const Spinner = () => {
  return (
    <div className="min-h-screen w-full absolute flex justify-center items-center spinner-container ">
      <div class="lds-grid ">
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  );
};

export default Spinner;
