import React from "react";
import slider from "../../img/Tanvir-slider1.jpg";
import "./Warrper.css";
import Hi from "../../img/icon/28-288401_waving-hand-emoji-svg-png-download-emoji-hand-removebg-preview.png";
import ScrollDown from "./scrollDown";
import { FaBehanceSquare } from "react-icons/fa";
import { SiLeetcode } from "react-icons/si";
const Slider = () => {
  return (
    <div className="container md:h-[700px] items-center flex md:flex-row flex-col justify-between  mx-auto">
      <div className="slider-items flex order-2 md:order-1 my-6 lg:my-2">
        <div className="social max-w-[20px] flex flex-col md:mr-[40px] mr-[10px]  ">
          <a
            href="https://www.linkedin.com/in/tanviralam625/"
            target="_blank"
            className="mb-2 hover:text-[#0A66C2]"
          >
            <i class="bx bxl-linkedin-square "></i>
          </a>
          <a
            href="https://github.com/Tanvir-Alam625"
            className="mb-2 hover:text-[#24292F]"
            target="_blank"
          >
            <i class="bx bxl-github"></i>
          </a>
          <a
            href="https://www.facebook.com/tanvir.alam625/"
            className="mb-2 hover:text-[#0D8CF0]"
            target="_blank"
          >
            <i class="bx bxl-facebook-square"></i>
          </a>
          <a
            href="https://www.behance.net/xv3"
            className="mb-2 hover:text-[#0D8CF0]"
            target="_blank"
          >
            <FaBehanceSquare />
          </a>
          <a
            href="https://leetcode.com/mdtanviralamht731/"
            className="mb-2 hover:text-[#FF8624]"
            target="_blank"
          >
            <SiLeetcode />
          </a>
        </div>
        <div className="info">
          <h4 className="uppercase mb-4 font-semibold text-2xl tracking-widest text-primary">
            <div className="warrper-container">
              <div className="warrper text-md md:text-xl lg:text-2xl">
                MERN Stack Developer
              </div>
              <div className="warrper text-md md:text-xl lg:text-2xl">
                ReactJS Developer
              </div>
              <div className="warrper text-md md:text-xl lg:text-2xl">
                Front End Developer
              </div>
              <div className="warrper text-md md:text-xl lg:text-2xl">
                Web Developer
              </div>
              <div className="warrper text-md md:text-xl lg:text-2xl">
                Self Learner
              </div>
            </div>
          </h4>
          <h2 className="flex text-xl my-2 font-semibold">
            Hi
            <img src={Hi} alt="Hi-img" className="hand" />, I'm Tanvir Alam
          </h2>
          <div className="description max-w-[500px] pr-4">
            <p className="md:text-md text-sm mb-2 ">
              I am passionate full stack & frontend Developer seeking a
              challenging role to develop scalable solutions through
              problem-solving and analytical skills. Committed to excellence
              attention to detail, and continuous learning in a dynamic,
              collaborative environment.
            </p>
          </div>
          <div className="slider-btn flex">
            <a
              className="button  flex"
              href="https://www.linkedin.com/in/md-tanvir-alam-a328341b3/"
              target="_blank"
            >
              Hire Me{" "}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="md:h-6 md:w-6 w-4 h-4 ml-2"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                strokeWidth={2}
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M17 8l4 4m0 0l-4 4m4-4H3"
                />
              </svg>
            </a>

            <a className="button-out" href="#contact">
              Contact Me{" "}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="md:h-6 md:w-6 w-4 h-4 ml-2"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                strokeWidth={2}
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M17 8l4 4m0 0l-4 4m4-4H3"
                />
              </svg>
            </a>
          </div>
          <ScrollDown />
        </div>
      </div>
      <div className=" order-1 md:order-2 flex justify-center my-8 md:my-0">
        {/* <div className="slider_img"></div> */}
        <img src={slider} alt="slider" className="slider_img" />
      </div>
    </div>
  );
};

export default Slider;
