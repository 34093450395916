import React from "react";
import Tanvir from "../../img/slider.png";
import Resume from "../../file/tanvir-alam-resume.pdf";

const About = () => {
  const info = [
    {
      id: 1,
      name: "Name",
      nameValue: "Md Tanvir Alam",
    },
    {
      id: 2,
      name: "Date of birth",
      nameValue: "25 June 2000",
    },
    {
      id: 3,
      name: "Address",
      nameValue: " Dhaka, Bangladesh",
    },
    {
      id: 4,
      name: "Post Code",
      nameValue: 1211,
    },
    {
      id: 5,
      name: "Phone",
      nameValue: "+8801704338645",
    },
    {
      id: 6,
      name: "Email",
      nameValue: "devtanvir01@gmail.com",
    },
  ];
  return (
    <div
      id="about"
      className="max-w-[1100px] mx-auto py-20  grid grid-cols-1 gap-6 lg:grid-cols-2"
    >
      <div className="img  p-4 max-h-[600px] flex justify-center items-center ">
        <img
          src={Tanvir}
          alt="tanvir-img"
          className="h-[500px] object-cover grayscale about_img rounded-2xl"
        />
      </div>
      <div className="about-items">
        <div className="title my-2">
          <h2 className=" md:text-2xl text-xl font-semibold about-title ">
            About Me
          </h2>
        </div>
        <br />
        <div className="info  ">
          <p className="text-sm md:text-md mb-2 ">
            I’m a Full Stack Developer. I have a Passion for Creating Clean,
            Beautiful, Interactive, Minimalistic, Responsive and User Friendly
            Developed by Modular, Scalable and Functional Code.
          </p>
          {info.map((information, index) => (
            <div className="name flex my-0" key={index}>
              <div className="w-[40%] my-0">
                <h4 className="md:text-2xl text-xl my-2 ">
                  {information.name}{" "}
                </h4>
              </div>
              <div className="name-value my-0 ">
                <h4 className="md:text-2xl text-xl my-2">
                  {information.nameValue}
                </h4>
              </div>
            </div>
          ))}
          <br />
          <a
            href={Resume}
            download={"Md Tanvir Alam Resume-MREN-stack-developer"}
          >
            <button className="button">
              Download Resume
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="md:h-6 md:w-6 w-3 h-3 download-icon"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                strokeWidth={2}
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4"
                />
              </svg>
            </button>
          </a>
        </div>
      </div>
    </div>
  );
};

export default About;
