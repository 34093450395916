import { Route,  Routes, useLocation } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "./App.css";
import Footer from "./components/Footer/Footer";
import Header from "./components/Header/Header";
import Home from "./components/Home/Home";

function App() {
  const location = useLocation();
  const pathName = location.pathname;
  const detailsLocation = pathName.split("/")[1];
  return (
    <div className="my_app">
      {!detailsLocation && <Header />}
      {/* <SideBar themes={themes} mode={mode} /> */}

      <Routes>
        <Route path="/" element={<Home />} />
        {/* <Route path="/details/:id" element={<ProjectDetails />} /> */}
      </Routes>
      {!detailsLocation && <Footer />}

      <ToastContainer />
    </div>
  );
}

export default App;
