import React, { useState } from "react";
import "./Header.css";
const Header = () => {
  const [showNavbar, setShowNavbar] = useState(false);
  return (
    <header className="header">
      <nav className="nav container">
        <a href="/" className="nav_logo">
          Tanvir
        </a>
        <div className={` ${showNavbar ? "show_nav nav_menu" : "nav_menu"}`}>
          <ul className="nav_list grid_custome">
            <li className="nav_item">
              <a href="#top" className="nav_link active_link">
                <i className="uil uil-estate nav_icon"></i>Home
              </a>
            </li>
            <li className="nav_item">
              <a href="#about" className="nav_link">
                <i className="uil uil-user nav_icon"></i>About
              </a>
            </li>
            <li className="nav_item">
              <a href="#skills" className="nav_link">
                <i className="uil uil-file-alt nav_icon"></i>Skills
              </a>
            </li>
            <li className="nav_item">
              <a href="#services" className="nav_link">
                <i className="uil uil-briefcase-alt nav_icon"></i>Services
              </a>
            </li>
            <li className="nav_item">
              <a href="#project" className="nav_link">
                <i className="uil uil-scenery nav_icon"></i>Projects
              </a>
            </li>
            <li className="nav_item">
              <a href="#contact" className="nav_link">
                <i className="uil uil-message nav_icon"></i>Contact
              </a>
            </li>
          </ul>
          <i
            onClick={() => setShowNavbar(!showNavbar)}
            className="uil uil-times nav_close"
          ></i>
        </div>
        <div
          onClick={() => setShowNavbar(!showNavbar)}
          className={`${showNavbar ? "none_toggle " : "nav_toggle"}`}
        >
          <i className="uil uil-apps toggle_icon"></i>
        </div>
      </nav>
    </header>
  );
};

export default Header;
