import React from "react";
import FrontEnd from "./FrontEnd";
import BackEnd from "./BackEnd";
import "./Skills.css"
const Skills =()=>{
    return(
        <div className="container" id="skills">
            <div className="title my-4 lg:mt-12 lg:mb-12 ">
                <h2 className="lg:text-4xl md:text-2xl text-xl font-bold title-project">
                Skills
                </h2>
            </div>
            <div className="skills_container container grid">
                <FrontEnd />
                <BackEnd />
            </div>
        </div>
    );

}
export default Skills;