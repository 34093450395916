import React from "react";

const FrontEnd = () => {
  return (
    <div className="skills_content">
      <h3 className="skills_title">Back End</h3>
      <div className="skills_box">
        <div className="skills_group">
          <div className="Skills_data">
            <i class="bx bxs-badge-check"></i>
          </div>
          <div className="">
            <h3 className="skills_name">NodeJS</h3>
            <span className="skills_level">Intermediate</span>
          </div>
        </div>
        <div className="skills_group">
          <div className="Skills_data">
            <i class="bx bxs-badge-check"></i>
          </div>
          <div className="">
            <h3 className="skills_name">ExpressJS</h3>
            <span className="skills_level">Advanced</span>
          </div>
        </div>
        <div className="skills_group">
          <div className="Skills_data">
            <i class="bx bxs-badge-check"></i>
          </div>
          <div className="">
            <h3 className="skills_name">MongoDB</h3>
            <span className="skills_level">Intermediate</span>
          </div>
        </div>
        <div className="skills_group">
          <div className="Skills_data">
            <i class="bx bxs-badge-check"></i>
          </div>
          <div className="">
            <h3 className="skills_name">Mongoose</h3>
            <span className="skills_level">Intermediate</span>
          </div>
        </div>
        <div className="skills_group">
          <div className="Skills_data">
            <i class="bx bxs-badge-check"></i>
          </div>
          <div className="">
            <h3 className="skills_name">JWT</h3>
            <span className="skills_level">Intermediate</span>
          </div>
        </div>
        <div className="skills_group">
          <div className="Skills_data">
            <i class="bx bxs-badge-check"></i>
          </div>
          <div className="">
            <h3 className="skills_name">MVC</h3>
            <span className="skills_level">Intermediate</span>
          </div>
        </div>
        <div className="skills_group">
          <div className="Skills_data">
            <i class="bx bxs-badge-check"></i>
          </div>
          <div className="">
            <h3 className="skills_name">SQL</h3>
            <span className="skills_level">Intermediate</span>
          </div>
        </div>
        <div className="skills_group">
          <div className="Skills_data">
            <i class="bx bxs-badge-check"></i>
          </div>
          <div className="">
            <h3 className="skills_name">PHP</h3>
            <span className="skills_level">Basic</span>
          </div>
        </div>
        <div className="skills_group">
          <div className="Skills_data">
            <i class="bx bxs-badge-check"></i>
          </div>
          <div className="">
            <h3 className="skills_name">REST API</h3>
            <span className="skills_level">Intermediate</span>
          </div>
        </div>
        <div className="skills_group">
          <div className="Skills_data">
            <i class="bx bxs-badge-check"></i>
          </div>
          <div className="">
            <h3 className="skills_name">Laravel</h3>
            <span className="skills_level">Basic</span>
          </div>
        </div>
        <div className="skills_group">
          <div className="Skills_data">
            <i class="bx bxs-badge-check"></i>
          </div>
          <div className="">
            <h3 className="skills_name">Python</h3>
            <span className="skills_level">Basic</span>
          </div>
        </div>
        <div className="skills_group">
          <div className="Skills_data">
            <i class="bx bxs-badge-check"></i>
          </div>
          <div className="">
            <h3 className="skills_name">MYSQL</h3>
            <span className="skills_level">Basic</span>
          </div>
        </div>
      </div>
    </div>
  );
};
export default FrontEnd;
