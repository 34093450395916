import React from "react";
import About from "./About";
import BLog from "./BLog";
import Contact from "./Contact";
import Projects from "./Projects";
import Slider from "./Slider";
import Skills from "../Skills/Skills";
import Services from "./Services"
const Home = () => {
  return (
    <div className="px-2">
      <Slider />
      <div className="container">
        <hr />
      </div>
      <About />
      <div className="container">
        <hr />
      </div>
      <Skills />
      <div className="container">
        <hr />
      </div>
      <Projects />
      <div className="container">
        <hr />
      </div>
      {/* <BLog /> */}
      <Services />
      <div className="container">
        <hr />
      </div>
      <Contact />
    </div>
  );
};

export default Home;
